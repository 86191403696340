/* 
  API Service
  Handles AXIOS API Calls generically
*/
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { EventService } from '@/services/event.service';
import MockService from '@/services/mock.service';
import { PermissionService } from '@/services/permission.service';
import { ActionApiRouteMapper } from '@/stores/actions.type';
import Qs from 'qs';
import { responseMapper } from '@/utils';
import { requestMapper } from '../utils/requestMapper';
import { SESSION_TIMEOUT_ERROR } from '../common/constants';

const LOADING_DELAY = 400; // ms
let activeRequests = 0;
let loadingTimeout = null;

const startLoading = () => {
  activeRequests++;
  
  if (loadingTimeout) {
    clearTimeout(loadingTimeout);
  }
  
  if (activeRequests === 1) { // Only start loading on first request
    loadingTimeout = setTimeout(() => {
      EventService.emit('xhr-event', true);
    }, LOADING_DELAY);
  }
};

const stopLoading = () => {
  activeRequests = Math.max(0, activeRequests - 1);
  
  if (loadingTimeout) {
    clearTimeout(loadingTimeout);
  }
  
  if (activeRequests === 0) { // Only stop loading when all requests are done
    EventService.emit('xhr-event', false);
  }
};

const ApiService = {
  /* Initialize the service */
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    Vue.axios.defaults.withCredentials = true;

    // Default Timeout
    Vue.axios.defaults.timeout = 600000; // 10 minutes

    // Initialize any Mock Services
    MockService.init(Vue.axios);

    // Add a request interceptor
    axios.interceptors.request.use(
      function (config) {
        // Do something before request is sent
        if (!config.untracked) startLoading();

        return config;
      }.bind(this),
      function (error) {
        // Do something with request error
        if (!error.config.untracked) stopLoading();

        //return Promise.reject(error);
      }.bind(this),
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        if (!response.config.untracked) stopLoading();

        return response;
      }.bind(this),
      function (error) {
        // Do something with response error
        if (!error.config.untracked) stopLoading();

        // Unauthorized responses for all except the AUTH/TOKEN AUTH API shoule emit a session-timeout
        // Exclude this logic when performing a token login
        if (error.response && error.response.status === 401) {
          if (PermissionService.isAuthenticatedApi(error.config.url)) {
            EventService.emit('session-timeout');
            return Promise.reject(SESSION_TIMEOUT_ERROR);
          } else if (
            error.config.url &&
            error.config.url.includes(ActionApiRouteMapper.PING)
          ) {
            // It's OK for ping to return a 401.
            return Promise.resolve({ status: 401 });
          }
        }
        return Promise.reject(error);
      }.bind(this),
    );
  },

  /* Set Headers */
  setHeader() {
    Vue.axios.defaults.headers.common['Accept'] = 'application/json';
  },

  /* Query is an alias to GET with Query Parameters */
  query(resource, params, action = null) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(resource, {
          params: params,
          paramsSerializer: (params) =>
            Qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then(({ data }) => {
          const response = responseMapper(action, data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* GET wrapper */
  get(resource, action = null) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .get(resource)
        .then(({ data }) => {
          const response = responseMapper(action, data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* POST wrapper */
  post(resource, params, action = null) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .post(`${resource}`, requestMapper(action, params), {
          withCredentials: true,
          credentials: 'include',
        })
        .then(({ data }) => {
          const response = responseMapper(action, data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* UPDATE wrapper */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /* PUT wrapper */
  put(resource, params, action = null) {
    return Vue.axios.put(`${resource}`, requestMapper(action, params));
  },

  patch(resource, params, action = null) {
    return new Promise((resolve, reject) => {
      Vue.axios
        .patch(`${resource}`, requestMapper(action, params))
        .then(({ data }) => {
          const response = responseMapper(action, data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /* DELETE wrapper */
  delete(resource) {
    return Vue.axios.delete(resource);
  },

  /* Wrapper to perform an UPLOAD of Multi-Part data */
  upload(resource, params) {
    return Vue.axios.post(`${resource}`, params, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /* Wrapper for untracked get */
  getBackground(resource) {
    return Vue.axios.get(resource, { untracked: true });
  },

  // File download without loading to a blob first
  downloadDirect(resource) {
    const link = document.createElement('a');
    link.href = Vue.axios.defaults.baseURL + resource;
    link.setAttribute('download', '');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  /* Wrapper to request a DOWNLOAD of resource */
  download(resource, name, mimeType) {
    return Vue.axios({
      url: `${resource}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const headers = response.headers;
      const dispositionHeader = headers['content-disposition'];
      let fname = null;

      try {
        fname = dispositionHeader.match(/filename="(.+)"/)[1];
      } catch {
        fname = null;
      }

      const filename = fname ? fname : name;

      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: mimeType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.type = mimeType;

        /* Open images/PDF's in new tab
        if (mimeType && (mimeType.includes("image") || mimeType.includes("pdf"))) {
          link.target = "_blank";
        } else {
          link.setAttribute("download", filename);
        } */
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      }
    });
  },

  /* Wrapper to request a DOWNLOAD of resource */
  downloadAsPost(resource, name, mimeType, payload) {
    return Vue.axios({
      url: `${resource}`,
      method: 'POST',
      responseType: 'blob', // important
      data: payload,
    }).then((response) => {
      const headers = response.headers;
      const dispositionHeader = headers['content-disposition'];
      let fname = null;

      try {
        fname = dispositionHeader.match(/filename="(.+)"/)[1];
      } catch {
        fname = null;
      }

      const filename = fname && fname === name ? fname : name;

      if (!window.navigator.msSaveOrOpenBlob) {
        // BLOB NAVIGATOR
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: mimeType }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.type = mimeType;

        // Open images/PDF's in new tab
        if (
          mimeType &&
          (mimeType.includes('image') || mimeType.includes('pdf'))
        ) {
          link.target = '_blank';
        } else {
          link.setAttribute('download', filename);
        }
        document.body.appendChild(link);
        link.click();
      } else {
        // BLOB FOR EXPLORER 11
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      }
    });
  },
};

export default ApiService;
