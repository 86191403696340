<template>
  <div>
    <v-layout v-resize="onResize" column>
      <v-data-table
        :headers="headers"
        :items="filteredLogs"
        :items-per-page="15"
        :hide-default-header="isMobile"
        class="responsive-table log-table"
        :class="{ mobile: isMobile }"
        :loading="tableLoading"
        ref="logTable"
        :page.sync="pagination.page"
        :server-items-length="totalItems"
        @pagination="updatePage"
        :custom-sort="customSort"
      >
        <template v-slot:top>
          <v-row class="filter-action-row align-center">
            <v-col class="status-col">
              <v-select
                v-model="filterStatus"
                :items="statuses"
                item-text="text"
                item-value="value"
                :backgroundColor="inputBackgroundColor"
                outlined
                placeholder="Status"
                ref="statusFilter"
                hide-details
                dense
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        class="prepend"
                        @click="clearStatusFilter()"
                      >
                        Clear Filter
                        <a class="close">
                          <div><i class="fas fa-times"></i></div>
                        </a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </template>

        <template slot="item" slot-scope="props" v-if="!tableLoading">
          <tr v-if="!isMobile">
            <td v-for="col in tableColumns" :key="col" :width="col.width">
              <div v-if="!col.isActionColumn">
                <span :class="col.classFn(props.item)">
                  {{ col.valueFn(props.item) }}
                </span>
              </div>
              <div v-else class="action-column">
                <v-menu
                  left
                  nudge-left="15"
                  nudge-bottom="25"
                  v-if="canRetryLog(props.item)"
                  min-width="125"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="actions" v-bind="attrs" v-on="on">
                      <v-icon small>fas fa-ellipsis-h</v-icon>
                    </span>
                  </template>
                  <v-list class="menu">
                    <v-list-item @click="retryLog(props.item)">
                      <v-list-item-title class="menu-item-wrapper">
                        <v-icon x-small>fas fa-redo</v-icon>
                        <span class="label">Retry</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <tr v-else>
            <td>
              <ul class="flex-content">
                <li class="flex-item" v-for="col in tableColumns" :key="col">
                  <div v-if="!col.isActionColumn">
                    <span class="mobile-label" v-if="col.dataLabel">
                      {{ col.dataLabel }}:
                    </span>
                    <span :class="col.classFn(props.item)">
                      {{ col.valueFn(props.item) }}
                    </span>
                  </div>
                  <div v-else class="actions-container">
                    <v-bottom-sheet v-if="canRetryLog(props.item)">
                      <template v-slot:activator="{ on, attrs }">
                        <span class="actions" v-bind="attrs" v-on="on">
                          <v-icon small>fas fa-ellipsis-h</v-icon>
                        </span>
                      </template>
                      <v-sheet height="auto">
                        <v-list class="sheet">
                          <v-list-item @click="retryLog(props.item)">
                            <v-list-item-title class="menu-item-wrapper">
                              <v-icon x-small>fas fa-redo</v-icon>
                              <span class="label">Retry</span>
                            </v-list-item-title>
                          </v-list-item>
                          <div class="bottom-spacer"></div>
                        </v-list>
                      </v-sheet>
                    </v-bottom-sheet>
                  </div>
                </li>
              </ul>
            </td>
          </tr>
        </template>
        <div class="no-results" slot="no-results" :value="true">
          Your search/filter found no results.
        </div>
        <div class="no-data" slot="no-data" :value="true">
          No logs found.
        </div>
      </v-data-table>
    </v-layout>
  </div>
</template>

<script>
import Themable from '@/common/mixins/themable.mixin';
import DataTableMixin from '@/common/mixins/datatable.mixin';
import { GET_LOGS, RETRY_LOG } from '@/stores/actions.type';
import {
  NOTIFICATION_TYPES,
  NotificationService,
} from '@/services/notification.service';

export default {
  data: () => ({
    logs: [],
    filterStatus: null,
    isMobile: false,
    tableLoading: false,
    statuses: [
      { text: 'Success', value: 'completed' },
      { text: 'Failed', value: 'failed' },
      { text: 'Processing', value: 'processing' },
      { text: 'Retrying', value: 'retrying' }
    ],
    totalItems: 0,
  }),

  mixins: [Themable, DataTableMixin],

  computed: {
    filteredLogs() {
      if (!this.filterStatus) return this.logs;
      
      const result = this.logs.filter(log => {
        return log.rawStatus === this.filterStatus;
      });
      return result;
    },

    headers() {
      return [
        {
          text: 'Resource',
          value: 'resource',
        },
        {
          text: 'Queue',
          value: 'queueName',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Result/Error',
          value: 'result',
        },
        {
          text: 'Retry Count',
          value: 'retryCount',
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'end',
        },
      ];
    },

    tableColumns() {
      return [
        {
          width: '20%',
          dataLabel: 'Resource',
          valueFn: (item) => this.capitalizeFirst(item.resource),
          classFn: () => '',
        },
        {
          width: '15%',
          dataLabel: 'Queue',
          valueFn: (item) => this.formatQueue(item.queueName),
          classFn: () => '',
        },
        {
          width: '15%',
          dataLabel: 'Status',
          valueFn: (item) => item.status,
          classFn: (item) => `status-pill ${item.status}`,
        },
        {
          width: '35%',
          dataLabel: 'Result/Error',
          valueFn: (item) => item.result,
          classFn: () => '',
        },
        {
          width: '10%',
          dataLabel: 'Retry Count',
          valueFn: (item) => item.retryCount,
          classFn: () => '',
        },
        {
          width: '5%',
          isActionColumn: true,
        },
      ];
    },
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 769;
    },

    clearStatusFilter() {
      this.filterStatus = null;
      this.$refs.statusFilter.blur();
    },

    canRetryLog(log) {
      return log.status === 'Failed' || log.status.toLowerCase() === 'failed';
    },

    customSort(items, [index], isDesc) {
      return items.sort((a, b) => {
        if (isDesc[0]) {
          return b[index] < a[index] ? -1 : 1;
        } else {
          return a[index] < b[index] ? -1 : 1;
        }
      });
    },

    fetchLogs() {
      this.tableLoading = true;

      const params = {
        page: this.pagination.page,
        limit: 15,
      };

      this.$store
        .dispatch(GET_LOGS, params)
        .then((response) => {
          this.logs = response.data.map(log => ({
            id: log._id,
            resource: log.resource,
            rawStatus: log.status,
            status: this.formatStatus(log.status),
            result: log.result || log.error || '-',
            retryCount: log.retryCount,
            queueName: log.queueName
          }));

          if (response.meta) {
            this.totalItems = response.meta.total;
            this.pagination.totalItems = response.meta.total;
            this.pagination.pageCount = response.meta.totalPages;
          }
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Error getting logs!',
              okTitle: 'Ok',
            },
            error,
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    formatStatus(status) {
      if (!status) return 'Unknown';
      
      const statusMap = {
        completed: 'Success',
        failed: 'Failed',
        processing: 'Processing',
        retrying: 'Retrying'
      };
      
      return statusMap[status.toLowerCase()] || status;
    },

    retryLog(log) {
      this.$store
        .dispatch(RETRY_LOG, { id: log.id })
        .then(() => {
          NotificationService.alert({
            type: NOTIFICATION_TYPES.SUCCESS,
            title: 'Success',
            message: 'Log retry initiated successfully!',
            okTitle: 'Ok',
          });
          this.fetchLogs();
        })
        .catch((error) => {
          NotificationService.alert(
            {
              type: NOTIFICATION_TYPES.ERROR,
              title: 'Error',
              message: 'Failed to retry log!',
              okTitle: 'Ok',
            },
            error,
          );
        })
    },

    capitalizeFirst(str) {
      if (!str) return '';
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    formatQueue(queueName) {
      if (!queueName) return '-';
      return queueName.split('_').map(this.capitalizeFirst).join(' ');
    },

    updatePage() {
      this.fetchLogs();
    },
  },

  created() {
    this.pagination.sortBy = 'resource';
    this.pagination.sortDesc = false;
  },

  mounted() {
    this.fetchLogs();
  },

  watch: {
    'pagination.page'() {
      this.fetchLogs();
    },
  },
};
</script>

<style lang="scss" scoped>
// Reuse existing styles from ClientTable.vue
.mobile {
  color: var(--color-text-dark);
}

.prepend {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--secondary-color);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.actions {
  padding: 2px 4px;
  line-height: 16px;

  &[aria-expanded='true'] {
    background-color: var(--secondary-color);
    border-radius: 4px;

    ::v-deep .v-icon {
      color: var(--primary-color);
    }
  }
}

.menu {
  background-color: var(--color-white);
  min-width: 120px;

  ::v-deep {
    .v-list-item {
      min-height: 24px !important;
      padding: 0;
    }
  }
}

.menu-item-wrapper {
  padding-left: 10px;
  color: var(--color-text) !important;
  font-size: 14px !important;
  font-weight: 700;
  margin-bottom: 0;
  padding: 8px 12px;
  margin: 0 4px;

  ::v-deep {
    .v-icon {
      font-weight: 700;
      color: var(--color-text) !important;
      margin-top: -2px;
    }
  }

  &:hover {
    color: var(--primary-color) !important;
    background-color: var(--input-bg-color);
    text-decoration: underline;

    ::v-deep .v-icon {
      color: var(--primary-color) !important;
    }
  }

  .label {
    padding-left: 8px;
  }
}

.status-col {
  max-width: 275px;
}

.action-column {
  text-align: end !important;
}

.log-table {
  font-weight: 500;
  color: var(--color-text);

  .status-pill {
    padding: 4px 15px;
    border-radius: 30px;
    line-height: 24px;
    font-size: 14px;

    &.Success {
      background-color: rgba(52, 182, 56, 0.25);
      color: var(--primary-color);
    }

    &.Failed {
      background-color: rgba(232, 44, 44, 0.25);
      color: var(--primary-color);
    }

    &.Processing {
      background-color: rgba(30, 59, 112, 0.15);
      color: var(--primary-color);
    }

    &.Retrying {
      background-color: rgba(20, 73, 173, 0.15);
      color: var(--primary-color);
    }
  }
}

// Mobile styles
@media screen and (max-width: 769px) {
  .filter-action-row {
    flex-direction: column;

    .status-col {
      max-width: 100%;
      padding: 4px 12px;
    }
  }

  .flex-content {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;

    .actions-container {
      position: absolute;
      top: 0;
      right: 0;
      line-height: normal;
    }

    .flex-item {
      padding: 5px;
      line-height: 24px;
      font-size: 16px;

      .mobile-label {
        font-weight: 400;
        margin-right: 10px;
      }
    }
  }
}
</style>
