export function interviewMapper(response) {
  const progress = response.template.pages.map((page) => {
    return {
      interviewName: 'onbord',
      pageName: page.name,
      lastCompleted: page.filled ? page.filled : null,
      instance: page.data ? page.data.instance : null,
      id: page._id,
      label: page.data ? page.data.label : null,
      status: page.status ? page.status : null,
      accountId: null,
      clientInterviewId: page._id,
      data: page.data,
    };
  });

  return {
    companyName: 'OnBord Demo Account',
    companyLogo: response.organisationLogo,
    mobile: response.client.primaryContact.mobile,
    progress,
    applicantName: `${response.client.primaryContact.firstName} ${response.client.primaryContact.lastName}`,
    uploads: response.documents,
  };
}
