// SESSION AUTH HEADER
export const SESSION_AUTH_HEADER = '_SESSION_LOCK';
export const ISO_DATE_SHORT = 'YYYY-MM-DD';
export const US_DATE_SHORT = 'MM/DD/YYYY';
export const INPUT_DATE_FMT = 'MMDDYYYY';
export const NO_DATE = '----';
export const IDLE_TIMEOUT_SECONDS = 7200; // 2 hours
export const IDLE_COUNTDOWN_SECONDS = 30;
export const EXPIRY_MINUTES = {
  EMAIL_CHANGE: 15,
};
export const MAX_ACCOUNTS_PER_TYPE = 5;
export const SESSION_TIMEOUT_ERROR = 'session timed out';

export const MENUS = {
  NOT_FOUND: { id: '404' },
  ERROR: { id: 'error' },
  LOGIN: { id: 'login' },
  VERIFY_CODE: { id: 'verify' },
  FORGOT_PASSWORD: { id: 'forgot-password' },
  RESET_PASSWORD: { id: 'reset-password' },
  SET_NEW_PASSWORD: { id: 'set-new-password' },
  HOME: { id: 'home' },
  COMPANIES: { id: 'companies' },
  MONITOR: { id: 'monitor' },
  DOCUMENTS: { id: 'documents' },
  COMPANY_PROFILE: { id: 'company-profile' },
  CUSTOM_QUESTIONS: { id: 'custom-questions' },
  USERS: { id: 'users' },
  ADD_USERS: { id: 'add-users' },
  CLIENTS: { id: 'clients' },
  USER_PROFILE: { id: 'user-profile' },
  ADD_USER: { id: 'add-user' },
  ADD_CLIENT_BATCH: { id: 'add-client-batch' },
  CUSTOM_QUESTIONS_TEMPLATES: { id: 'custom_questions_templates' },
  MESSAGE_TEMPLATES: { id: 'message-templates' },
  TRANSITIONS: {
    TRANSITION_TYPE: { id: 'transition-type' },
    TRANSITION_CLIENTS: { id: 'transitions' },
    TRANSITION_BATCH: { id: 'transition-batch' },
    STATEMENT_UPLOAD: { id: 'statement-upload' },
    SUCCESS_TRANSITION: { id: 'success-transition' },
    STATEMENT_SUBMITTED: { id: 'statement-submitted' },
    TRANSITION_HOUSEHOLD: { id: 'transition-household-table' },
    EDIT_TRANSITION: { id: 'edit-transition' },
    ENTER_MANUALLY: {
      CLIENT_ONE_NAME: { id: 'client-one-name' },
      CLIENT_TWO_NAME: { id: 'client-two-name' },
      CLIENT_ONE_EMAIL: { id: 'client-one-email' },
      CLIENT_TWO_EMAIL: { id: 'client-two-email' },
      CLIENT_ONE_PHONE: { id: 'client-one-phone' },
      CLIENT_TWO_PHONE: { id: 'client-two-phone' },
      FIRM_ROLES: { id: 'firm-roles' },
      HOUSEHOLD_VALUE: { id: 'household-value' },
      REVIEW: { id: 'review' },
    },
    TRANSITION_FORM: { id: 'transition-form' },
    TRANSITION_FORM_SELECTION: { id: 'client-selection' },
    EMAIL_REGISTER: { id: 'email-register' },
  },
  ADVISOR: {
    ADD_CLIENT: { id: 'add-client' },
    CLIENT: {
      CLIENT_SELECTION: { id: 'clientSelection' },
      DATA_SELECTION: { id: 'dataSelection' },
      CLIENT_ONE_NAME: { id: 'clientOneName' },
      CLIENT_ONE_EMAIL: { id: 'clientOneEmail' },
      CLIENT_ONE_PHONE: { id: 'clientOnePhone' },
      CLIENT_ADD_SECOND: { id: 'clientAddSecond' },
      CLIENT_TWO_NAME: { id: 'clientTwoName' },
      CLIENT_TWO_EMAIL: { id: 'clientTwoEmail' },
      CLIENT_TWO_PHONE: { id: 'clientTwoPhone' },
      ACCOUNT_SELECTION: { id: 'accountSelection' },
      ACCOUNT_FEATURES: { id: 'accountFeatures' },
      ADVISORY_RATE: { id: 'advisoryRate' },
      FIRM_ROLES: { id: 'firmRoles' },
      CUSTOM_QUESTIONS: { id: 'customQuestions' },
      REVIEW_AND_FINISH: { id: 'reviewAndFinish' },
    },
  },
  INTERVIEW: {
    AUTHENTICATE: { id: 'authenticate-interview' },
    UNAUTHENTICATED: { id: 'interview-exited' },
    SEND_EMAIL_LINK: { id: 'request-email-link' },
  },
  CLIENT: {
    HOME: { id: 'client-home' },
    CITIZENSHIP: { id: 'us_citizen' },
    LEGAL_NAME: { id: 'name' },
    LEGAL_ADDRESS: { id: 'address' },
    SSN: { id: 'ssn' },
    DOB: { id: 'dob' },
    ALTERNATE_PHONE: { id: 'phone' },
    EMPLOYMENT_STATUS: { id: 'employment' },
    COMPANY_ADDRESS: { id: 'company' },
    JOB_DESCRIPTION: { id: 'job' },
    COMPANY_OFFICIAL: { id: 'vip' },
    BENEFICIAL_INTEREST: { id: 'conflict_of_interest' },
    ACCOUNT_PRIMARY_BENEFICIARIES: { id: 'primary_beneficiaries' },
    ACCOUNT_CONTINGENT_BENEFICIARIES: { id: 'contingent_beneficiaries' },
    CUSTOM_QUESTIONS: { id: 'custom_questions' },
    FINISH: { id: 'finish' },
  },
  QUEUE_LOGS: { id: 'logs' },
};

// SESSION Storage keys
export const SESSION_KEYS = {
  IS_AUTHENTICATED: 'isAuthenticated',
  IS_CLIENT_AUTHENTICATED: 'isClientAuthenticated',
  SESSION_TOKEN: 'uuid',
  CURRENT_COMPANY: 'currentCompany',
  CURRENT_USER: 'currentUser',
  CURRENT_PHONE: 'currentPhone',
  CURRENT_CLIENT: 'workingClient',
  CLIENT_PROGRESS: 'clientProgress',
  USER_ID: 'userId',
  SELECTED_TRANSITION_TYPE: 'selectedTransitionType',
  TRANSITION_HOUSEHOLD: 'transitioningHousehold',
  TRANSITION_HOUSEHOLDS: 'transitionHouseholds',
};

// LOCAL Storage keys
export const LOCAL_KEYS = {
  DISABLE_ADD_CLIENT_INSTRUCTIONS: 'noAddClientInstructions',
  DISABLE_CLIENT_INSTRUCTIONS: 'noClientInstructions',
  AUTH_TYPE: 'xat',
  COMPANY_LOGO: 'company_logo',
};

// AUTH TYPES
export const AUTH_TYPES = {
  USER: 'u',
  UNAUTHENTICATED_CLIENT: 'c',
};

// CLIENT EXIT KEYS
export const CLIENT_EXIT_KEYS = {
  EXIT_INCOMPLETE_SAVE: 'ES',
  EXIT_INCOMPLETE_EXIT: 'EX',
  EXIT_COMPLETE: 'C',
  EXIT_CANT_PROGRESS: 'X',
  EXIT_NOT_AUTHORIZED: 'N',
  EXIT_SESSION_TIMEOUT: 'ET',
};

// Dialog Decisions
export const DIALOG_DECISIONS = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  SKIP: 'Skip',
};

// Contact Types
export const CONTACT_TYPES = {
  NEW: 'New',
  EXISTING: 'Existing',
};

// Data Types
export const DATA_TYPES = {
  TYPE_ONE: 'Add/Update CRM & Send ADV/Form CRS',
  TYPE_TWO: 'Add/Update CRM, Send ADV/Form CRS & Open Accounts',
  TYPE_THREE: 'Update CRM, Open Accounts for Existing Client(s)',
};

// Asset Keys
export const ASSET_KEYS = {
  CUSTOM_LOGO: 'logo?useDefault=false',
  LOGO: 'logo',
  ADV2A: 'ADV2A',
  ADV2B: 'ADV2B',
  CRS: 'CRS',
  ADVISORY_AGREEMENT: 'advisoryAgreement',
  NonSolicit: 'nonSolicit',
};

// Internal Account Types
export const ACCOUNT_TYPES = {
  SINGLE_NAME_BROKERAGE: 'Single-Name Brokerage',
  JOINT_NAME_BROKERAGE: 'Joint-Name Brokerage',
  IRA: 'IRA',
  ROTH_IRA: 'Roth IRA',
  OFFLINE_TRUST: 'Trust (offline)',
  OFFLINE_INHERITED_IRA: 'Inherited IRA (offline)',
  OFFLINE_SIMPLE_IRA: 'Simple IRA (offline)',
  OFFLINE_CORPORATE: 'Corporate (offline)',
  OFFLINE_OTHER: 'Other (offline)',
};

// DB Account Types
export const DB_ACCOUNT_TYPES = {
  SINGLE_NAME_BROKERAGE: 'brokerage',
  JOINT_NAME_BROKERAGE: 'joint',
  IRA: 'ira',
  ROTH_IRA: 'roth',
  OFFLINE_TRUST: 'offline_trust',
  OFFLINE_INHERITED_IRA: 'offline_inherited_ira',
  OFFLINE_SIMPLE_IRA: 'offline_simple_ira',
  OFFLINE_CORPORATE: 'offline_corporate',
  OFFLINE_OTHER: 'offline_other',
};

// Internal Joint Name Brokerage Types
export const JOINT_NAME_BROKERAGE_TYPES = {
  JTWROS: 'JTWROS',
  TENANTS_IN_COMMON: 'Joint Tenants in Common',
  TENANTS_BY_ENTIRETY: 'Tenants by Entirety',
};

// DB Joint Name Brokerage Types
export const DB_JOINT_NAME_BROKERAGE_TYPES = {
  JTWROS: 'JTWROS',
  TENANTS_IN_COMMON: 'JTIC',
  TENANTS_BY_ENTIRETY: 'entirety',
};
export const INDIVIDUAL_OWNERSHIP = 'individual';

// Internal Features Types
export const FEATURES_TYPES = {
  MONEY_LINK: 'Money Link',
  ACAT: 'ACAT',
  IRA_DISTRIBUTION: 'IRA Distribution',
};

// DB Features Types
export const DB_FEATURES_TYPES = {
  MONEY_LINK: 'MoneyLink',
  ACAT: 'ACAT',
  IRA_DISTRIBUTION: 'IRADistribution',
};

// Rate Types
export const RATE_TYPES = {
  FLAT: 'Flat % across ALL accounts',
  MANUAL: 'Manually assign % by account',
};
export const DEFAULT_RATE = '1.00';

// Employment Statuses
export const EMPLOYMENT_STATUSES = {
  EMPLOYED: 'Employed',
  SELF_EMPLOYED: 'Self-Employed',
  STUDENT: 'Student',
  HOMEMAKER: 'Homemaker',
  RETIRED: 'Retired',
  NOT_EMPLOYED: 'Not Employed',
};

// Job Description
export const JOB_DESCRIPTIONS = {
  PROFESSIONAL: 'Professional',
  TRADE_OR_SERVICE: 'Trade or Service',
  GOVERNMENT: 'Government',
  OTHER: 'Other',
};
export const JOB_TYPE_TRADE = 'Trade';

// Job Types
export const JOB_TYPES = {
  professional: [
    'Business Owner',
    'Executive',
    'Medical Professional',
    'Legal Professional',
    'Accounting Professional',
    'Financial Services',
    'IT Professional',
    'Educator',
    'Administrative Services',
    'Sales Marketing',
    'Consultant',
    'Other Professional',
  ],
  government: [
    'U.S Government Employee',
    'Foreign Government Employee',
    'Military',
  ],
};

// DocuSign Document Types
export const DOCUSIGN_DOCUMENT_TYPES = [
  'SchwabIraDistributionForm',
  'SchwabIraAccountApplication',
  'SchwabCoversheet',
  'SchwabTransferAccountForm',
  'SchwabMoneyLinkElectronicTransferForm',
  'SchwabOnePersonalAccountApplication',
];

// Available Message Placeholders
export const MESSAGE_PLACEHOLDERS = [
  '[clientFirstName]',
  '[clientLastName]',
  '[advisorFirstName]',
  '[advisorLastName]',
  '[organizationName]',
  '[organizationPhone]',
  '[emailLink]',
  '[statementLink]',
];

//Transition Type
export const TRANSITION_TYPES = Object.freeze({
  PROTOCOL: 'Protocol',
  NON_PROTOCOL: 'NonProtocol',
  NONE: 'None',
});

export const TRANSITION_OPTION_TYPE = Object.freeze({
  UPLOAD_CSV: 'Upload CSV',
  ENTER_MANUALLY: 'Enter Manually',
  NONE: 'none',
});

export const TRANSITION_STATUSES = {
  STATEMENT_PENDING: 'StatementPending',
  EMAIL_PENDING: 'EmailPending',
  STATEMENT_UPLOADED: 'StatementUploaded',
  NONSOLICIT_PENDING: 'NonSolicitPending',
  DRAFT: 'Draft',
  TRANSITIONED: "Transitioned",
};

// Beneficiary Types
export const BENEFICIARY_TYPES = {
  PRIMARY: 'Primary',
  CONTINGENT: 'Contingent',
};

// Picker Types
export const PICKER_TYPES = {
  ADVISORY_RATE: { id: 'AdvisoryRate', tickAmount: 0.05, decimalDigits: 2 },
  ALLOCATION: { id: 'AllocationAmount', tickAmount: 1.0, decimalDigits: 0 },
};

// Company Statuses
export const COMPANY_STATUSES = {
  ACTIVE: 'Active',
  DISABLED: 'Disabled',
};

// User Statuses
export const USER_STATUSES = {
  ACTIVE: 'Active',
  PENDING: 'Pending',
  ARCHIVED: 'Archived',
};

// User Roles
export const USER_ROLES = {
  PRODUCT_ADMIN: { id: 'SuperAdmin', name: 'Product Administrator' },
  COMPANY_ADMIN: { id: 'CompanyAdmin', name: 'Company Administrator' },
  REP: { id: 'Representative', name: 'Representative' },
};

// Client Statuses
export const CLIENT_STATUSES = {
  SENT: 'Sent',
  DRAFT: 'Draft',
  READY: 'Ready',
  CLIENT_COMPLETE: 'Client Complete',
  PENDING_DOCUSIGN_REVIEW: 'Pending DocuSign Review',
  WAITING_FOR_SIGNATURE: 'Waiting for Signature',
  SIGNED: 'Signed',
  ARCHIVED: 'Archived',
};

// Client ONBORD Statuses
export const CLIENT_ONBORD_STATUSES = {
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
};

// Fetch Client Statuses
export const FETCH_CLIENT_STATUSES = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  ALL: 'all',
};

// Alternate Phone Types
export const ALTERNATE_PHONE_TYPES = ['Home', 'Work', 'Other'];

// User Profile Section Types
export const USER_PROFILE_SECTION_TYPES = {
  BASIC: 'Basic',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  REDTAIL: 'Redtail',
  MOBILE_PHONE: 'Phone',
  NEW_USER: 'NewUser',
};

// Company Profile Section Types
export const COMPANY_PROFILE_SECTION_TYPES = {
  NEW: 'New',
  BASIC: 'Basic',
  ADDRESS: 'Address',
  DOCUMENTS: 'Documents',
  DOCUSIGN: 'Docusign',
  THEME: 'Theme',
};

// Firm Role Types
export const FIRM_ROLE_TYPES = {
  PRIMARY_ADVISOR: 'primaryAdvisor',
  PROVIDES_ADVICE: 'providesAdvice',
  PRIMARY_CSA: 'primaryCSA',
  SECONDARY_CSA: 'secondaryCSA',
};

// Document Types
export const DOCUMENT_TYPES = {
  FINRA_LETTER: 'FINRALetter',
  MONEY_LINK_CHECK: 'MoneyLinkCheck',
  ACAT_BANK_STATEMENT: 'ACATBankStatement',
  IRA_DISTRIBUTION_CHECK: 'IRADistributionCheck',
};

// Phone Regex for international numbers with country code
export const PHONE_REGEX_INTERNATIONAL = /^\+\d{1,3}\d{7,14}$/;

// Phone Regex for local US numbers without country code
export const PHONE_REGEX_US =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

// Update your constants.js file
export const COUNTRY_CODE_REGEX = /^\+\d{1,3}$/; // Country code with '+', followed by 1 to 3 digits
export const PHONE_NUMBER_REGEX = /^\d{7,14}$/; // 7 to 14 digits for the phone number

// Master Account Number Regex
export const MASTER_ACCOUNT_REGEX = /^\(?([0-9]{4})\)?[-]?([0-9]{4})$/;

// States
// TODO: Filter non US 50 states?
export const STATES = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
];

export const SUFFIXES = ['Jr.', 'Sr.', 'II', 'III', 'IV', 'V'];

export const LOG_STATUSES = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  RETRYING: 'Retrying'
};

/* eslint-disable */
export const validateArray = (value, vm) => {
  return value !== null && value.length > 0;
};

/* Is the date passed in a valid date in the format */
export const validInputDate = (value) => {
  if (!value) return true;

  return window.moment(value, [INPUT_DATE_FMT, US_DATE_SHORT], true).isValid();
};

/* Is the zip code passed in a valid format */
export const validateZip = (value) => {
  if (!value) return true;

  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
};

// In your component script
export const validateCountryCode = (value) => {
  return COUNTRY_CODE_REGEX.test(value);
};

export const validatePhoneNumber = (value) => {
  return PHONE_NUMBER_REGEX.test(value);
};

/* Custom phone format validator */
export const validatePhoneLocal = (value) => {
  if (!value) return true;

  return PHONE_REGEX_US.test(value);
};

/* Format seconds as a duration hh:mm:ss */
export const formatDurationSeconds = (v, stringFormat = false) => {
  const separator = stringFormat ? " " : ":";
  if (v === null) return `00${separator}00${separator}00`;
  if (isNaN(v) || v < 0) return "Invalid";

  const hours = v / (60 * 60);
  const absoluteHours = Math.floor(hours);
  const hourString = stringFormat
    ? absoluteHours > 0
      ? `${absoluteHours}H`
      : ""
    : `${absoluteHours}`.padStart(2, 0);

  //Get remainder from hours and convert to minutes
  const minutes = (hours - absoluteHours) * 60;
  const absoluteMinutes = Math.floor(minutes);
  const minuteString = stringFormat
    ? absoluteMinutes > 0
      ? `${absoluteMinutes}M`
      : ""
    : `${absoluteMinutes}`.padStart(2, 0);

  //Get remainder from minutes and convert to seconds
  const seconds = (minutes - absoluteMinutes) * 60;
  const absoluteSeconds = Math.round(seconds);
  const secondString = stringFormat
    ? absoluteSeconds > 0
      ? `${absoluteSeconds}S`
      : ""
    : `${absoluteSeconds}`.padStart(2, 0);

  return `${hourString}${separator}${minuteString}${separator}${secondString}`;
};

/* Format a Phone */
export const formatPhone = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;

  // If the phone number is already in international format, return as is
  if (PHONE_REGEX_INTERNATIONAL.test(phoneNumber)) {
    return phoneNumber;
  }

  // If the phone number is in US local format, convert it to international format
  const match = PHONE_REGEX_US.exec(phoneNumber);
  if (match) {
    return `+1${match[1]}${match[2]}${match[3]}`;
  }

  // If the phone number does not match either, just return it as is
  return phoneNumber;
};

export const formatPhoneLocal = (n) => {
  if (!n) return n;
  n = n.startsWith("+1") ? n.slice(2) : n;
  return n.replace(/\D+/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
};

/* Format a date */
export const formatDate = (d, fmt = ISO_DATE_SHORT) => {
  return d ? window.moment(d).format(fmt) : window.moment().format(fmt);
};

/* Determine Password Complexity */
export const passwordComplexity = (value) => {
  // Ignore if empty
  if (!value || value.length === 0) return true;

  // Minimum of 1 Uppercase Letter
  if (false === /[A-Z]/.test(value)) return false;

  // Minimum of 1 Number
  if (false === /\d/.test(value)) return false;

  // Minimum of 1 Special Character
  if (false === /[^A-Za-z0-9]/.test(value)) return false;

  return true;
};

export const interviewStarted = (clientProgress) => {
  if (!clientProgress || !clientProgress.progress) return false;
  return clientProgress.progress.some((p) => {
    return !!p.lastCompleted;
  });
};

export const getPageNotFilled = (clientProgress) => {
  let pageNotFilled = undefined;
  if (clientProgress) {
    pageNotFilled = clientProgress.progress.find((p) => {
      return !p.lastCompleted;
    });
  }
  return pageNotFilled;
};

export const getClientPage = (
  clientProgress,
  pageName,
  instance = null,
  questionId = null
) => {
  let progressPage = undefined;
  if (clientProgress) {
    progressPage = clientProgress.progress.find((p) => {
      if (p.instance && instance) {
        return (
          p.pageName === pageName &&
          p.instance === instance.name &&
          p.label === instance.label
        );
      }
      if (p.data && p.data.question) {
        return p.pageName === pageName && p.data.question._id === questionId;
      }
      return p.pageName === pageName;
    });
  }
  return progressPage;
};

export const getClientPageIndex = (
  clientProgress,
  pageName,
  instance = null,
  questionId = null
) => {
  let progressPage = getClientPage(
    clientProgress,
    pageName,
    instance,
    questionId
  );
  if (progressPage) {
    return clientProgress.progress.indexOf(progressPage);
  }
  return -1;
};
