import { checkOrganisationSetupComplete } from '../../checkSetupComplete';
import { isEmpty } from 'lodash';

export function organizationMapper(response) {
  const {
    _id,
    name,
    riaName,
    phone,
    docuSign,
    transitions,
    allowSendTransitionNotifications,
    address,
    externalAccounts,
    assets,
    status,
    crmInitialized,
    selectedCRM,
    configuration,
    notificationTemplates,
  } = response || {};

  const docusignLinked =
    !!externalAccounts &&
    externalAccounts.some(
      ({ integrationType, integrationConfig }) =>
        integrationType === 'DocumentSigning' && !isEmpty(integrationConfig),
    );

  return {
    id: _id,
    setupComplete: checkOrganisationSetupComplete(response),
    name,
    phone,
    address1: address ? address.street : null,
    address2: null,
    city: address ? address.city : null,
    state: address ? address.state : null,
    zip: address ? address.zip : null,
    iaFirmName: riaName,
    docusignLinked,
    docusignDisabled: !docuSign,
    transitionsDisabled: !transitions,
    transitionNotificationsDisabled: !allowSendTransitionNotifications,
    externalAccounts,
    assets,
    status,
    crmInitialized,
    crmConfiguration: configuration,
    selectedCRM,
    notificationTemplates,
  };
}
