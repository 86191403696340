<template>
  <div>
    <log-table v-if="tab === 0"></log-table>
  </div>
</template>

<script>
import LogTable from '@/components/advisor/tables/LogTable';
import { mapGetters } from 'vuex';

export default {
  name: 'LogList',

  components: {
    LogTable,
  },

  data() {
    return {
      tab: 0,
    };
  },
  created() {},

  computed: {
    ...mapGetters(['currentCompany']),
  },
  methods: {
    toggleView() {
      this.isClientsView = !this.isClientsView;
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tabs {
  .v-tab {
    text-transform: uppercase;
    color: var(--color-white);
    background-color: transparent;
  }

  .v-tab--active {
    color: var(--primary-color);
    background-color: var(--input-bg-color);
    border-bottom: 5px solid var(--primary-color);
  }
  .v-tabs-slider {
    display: none;
  }
}
</style>
