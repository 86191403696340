<template>
  <div class="menu-links" :class="containerClass">
    <a
      @click="go(MENUS.USER_PROFILE.id, { id: currentUser.user.id })"
      v-if="canManageOwnProfile"
      class="dd-link"
    >
      Profile
    </a>
    <div v-if="canManageOwnProfile" class="divider"></div>
    <a
      @click="go(MENUS.COMPANY_PROFILE.id, { id: currentUser.organisation.id })"
      v-if="canManageCompanyProfile"
      class="dd-link"
    >
      Company Settings
    </a>
    <a @click="go(MENUS.MONITOR.id)" v-if="canViewMonitor" class="dd-link">
      Monitor
    </a>
    <a @click="go(MENUS.COMPANIES.id)" v-if="canViewCompanies" class="dd-link">
      Companies
    </a>
    <a @click="go(MENUS.DOCUMENTS.id)" v-if="canViewCompanies" class="dd-link">
      Documents
    </a>
    <a @click="go(MENUS.USERS.id)" v-if="canViewUsers" class="dd-link">
      Users
    </a>
    <a @click="go(MENUS.CLIENTS.id)" v-if="canViewClients" class="dd-link">
      Clients
    </a>
    <a @click="go(MENUS.QUEUE_LOGS.id)" v-if="canViewLogs" class="dd-link">
      Events
    </a>
    <div class="divider"></div>
    <a v-if="canShowCustomerSupport" class="dd-link"> Customer Support </a>
    <a @click="signout()" class="dd-link"> Sign out </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { MENUS } from '@/common/constants';
import { LOGOUT } from '@/stores/actions.type';
import { EventService } from '@/services/event.service';
import { PermissionService, PERMISSIONS } from '@/services/permission.service';

export default {
  props: {
    showHover: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [],

  data: () => ({
    MENUS: MENUS,
  }),
  computed: {
    ...mapGetters(['currentUser']),

    canManageOwnProfile() {
      return PermissionService.hasPermission(
        PERMISSIONS.USER.MANAGE_OWN_PROFILE,
      );
    },
    canManageProduct() {
      return PermissionService.hasPermission(
        PERMISSIONS.COMPANY.MANAGE_PRODUCT,
      );
    },
    canManageCompanyProfile() {
      //return false;
      return PermissionService.hasPermission(
        PERMISSIONS.COMPANY.MANAGE_COMPANY_PROFILE,
      );
    },
    canShowCustomerSupport() {
      return false;
    },
    canViewCompanies() {
      return PermissionService.hasPermission(
        PERMISSIONS.COMPANY.LIST_COMPANIES,
      );
    },
    canViewUsers() {
      return PermissionService.hasPermission(PERMISSIONS.USER.LIST_USERS);
    },
    canViewMonitor() {
      return PermissionService.hasPermission(
        PERMISSIONS.COMPANY.MONITOR_ACTIVITY,
      );
    },
    canViewClients() {
      return (
        PermissionService.hasPermission(PERMISSIONS.CLIENT.LIST_ANY_CLIENT) ||
        PermissionService.hasPermission(PERMISSIONS.CLIENT.LIST_OWN_CLIENT)
      );
    },
    canViewLogs() {
      return PermissionService.hasPermission(PERMISSIONS.COMPANY.MONITOR_LOGS);
    },
    containerClass() {
      return !this.$vuetify.breakpoint.mdAndDown
        ? `dropdown-list ${this.showHover ? 'show' : ''}`
        : '';
    },
  },
  methods: {
    /* Peform signout */
    signout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: MENUS.LOGIN.id });
      });
    },

    // Emit event indicating navigation to a page
    go(id, params = {}) {
      this.$router.push({ name: id, params: params }).catch(() => {
        EventService.emit('toggle-main-drawer');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-links {
  display: flex;
  flex-direction: column;

  .dd-link {
    padding: 11px 0;
    color: var(--color-text);
    font-weight: 700;
  }

  .divider {
    height: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: var(--secondary-color);
  }

  &.dropdown-list {
    position: absolute;
    left: auto;
    top: 100%;
    right: 0px;
    bottom: auto;
    display: none !important;
    min-width: 12rem;
    padding: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: var(--secondary-color);
    border-radius: 5px;
    background-color: var(--color-white);
    text-align: left;

    box-shadow: 0 0 15px 0 var(--secondary-color);

    &.show {
      display: flex !important;
      opacity: 1 !important;
      right: 15px;
      top: 68px;
    }
    .dd-link {
      margin-right: 0px;
      margin-left: 0px;
      padding: 6px 10px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
    }

    .dd-link:hover {
      background-color: var(--input-bg-color);
      color: var(--primary-color);
      text-decoration: none;
    }
    .dd {
      color: var(--color-text);
      margin-left: 30px;
      font-size: 18px;
      line-height: 26px;
      cursor: pointer;
    }

    .dd:hover {
      color: var(--primary-color);
    }
  }
}
</style>
